<h2 mat-dialog-title>{{ "login.resetPassword" | translate }}</h2>
<form
  class="login-form flexbox direction-column"
  [formGroup]="forgotPasswordForm"
  (ngSubmit)="submit()"
>
  <mat-dialog-content>
    <p>{{ "login.provideEmail" | translate }}</p>
    <p>{{ "login.resetPasswordLinkWillBeSent" | translate }}</p>
    <mat-form-field class="mail w-100-percent no-background smaller-font email">
      <mat-label>{{ "controls.email" | translate }}</mat-label>
      <mat-icon matPrefix>email</mat-icon>
      <input matInput formControlName="email" type="email" />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button mat-stroked-button mat-dialog-close>
      {{ "buttonLabels.cancel" | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!forgotPasswordForm.valid"
    >
      <span class="button-label">{{ "buttonLabels.send" | translate }}</span>
    </button>
  </mat-dialog-actions>
</form>
