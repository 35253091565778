import { Injectable, signal } from '@angular/core';
import { MatDrawer, MatDrawerToggleResult } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  /** @Info May be needed for breadcrumb **/
  // activeContextNavigationItem$ = new BehaviorSubject<string>('');
  // activeDetailsForm$ = new BehaviorSubject<string>('');

  // @Info may be needed in the future
  // minimizedSig = signal<boolean>(true);
  toggledSig = signal<MatDrawerToggleResult>('close');
  private mainNavDrawer!: MatDrawer;

  /**
   * Sets the main navigation drawer.
   * @param {MatDrawer} drawer - The main navigation drawer.
   */
  setDrawer(drawer: MatDrawer) {
    this.mainNavDrawer = drawer;
  }

  /**
   * Toggles the main navigation drawer.
   * @returns {Promise<void>} A promise resolving to void.
   */
  async toggle(): Promise<void> {
    const toggleResult: MatDrawerToggleResult =
      (await this.mainNavDrawer?.toggle()) ?? 'close';
    this.toggledSig.set(toggleResult);
  }

  // @Info May be needed in the future
  // /**
  //  * Minimizes or maximizes the main navigation.
  //  * @param {boolean} _minimized - Indicates whether to minimize or maximize the.
  //  */
  // minimize(_minimized: boolean) {
  //   this.minimizedSig.set(_minimized);
  // }
  // toggleMinimize() {
  //   this.minimizedSig.set(!this.minimizedSig())
  // }
  //
  // setActiveContextNavigationItem(item: string) {
  //   this.activeContextNavigationItem$.next(item)
  // }
  //
  // setActiveDetailsForm(formName: string) {
  //   this.activeDetailsForm$.next(formName)
  // }
}
