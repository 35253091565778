import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgClass } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation/navigation.service';
import { MainNavigationComponent } from '../main-navigation/main-navigation.component';
import { UserInformationComponent } from '../user-information/user-information.component';

@Component({
  selector: 'eeule-admin-main-drawer',
  templateUrl: './main-drawer.component.html',
  styleUrl: './main-drawer.component.scss',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    NgClass,
    MainNavigationComponent,
    UserInformationComponent,
  ],
})
export class MainDrawerComponent implements AfterViewInit {
  @ViewChild('drawer') drawer!: MatDrawer;

  isHandset$: Observable<boolean> = this._breakpointObserver
    .observe(Breakpoints.HandsetPortrait)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    public _breakpointObserver: BreakpointObserver,
    public _navigationService: NavigationService
  ) {}

  ngAfterViewInit() {
    this._navigationService.setDrawer(this.drawer);
  }
}
